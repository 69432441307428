<template>
    <v-card class='incident-details-form'>
        <v-toolbar flat outlined>
            <v-icon @click="close()">mdi-close</v-icon>

            <v-spacer />

            <v-toolbar-title>Edit incident details</v-toolbar-title>

            <v-spacer />
            
            <!-- Removed :disabled="!isValid" - engineers want to partial save -->
            <v-btn @click="save()" small text>Save</v-btn>
        </v-toolbar>

        <v-card-text>
            <v-container>
                <v-form
                    ref="form"
                    v-model="isValid"
                >
                    <v-row v-if="isErs">
                        <v-select
                            label="Is the ERS in the business premise?"
                            v-model="businessPremiseIdLocal"
                            :items="lookups.allBoolOptions"
                            item-text="name"
                            item-value="id"
                            :rules="rules.required"
                            clearable
                        ></v-select>
                    </v-row>
                    
                    <v-row v-if="isErs">
                        <v-select
                            label="Is this ERS drop cable related?"
                            v-model="dropCableIdLocal"
                            :items="lookups.allBoolOptions"
                            item-text="name"
                            item-value="id"
                            :rules="rules.required"
                            clearable
                        ></v-select>
                    </v-row>
                    
                    <v-row v-if="isErs">
                        <v-select
                            label="Cable type"
                            v-model="cableTypeIdLocal"
                            :items="lookups.allCableTypes"
                            item-text="name"
                            item-value="id"
                            :rules="rules.required"
                            clearable
                        ></v-select>
                    </v-row>
                    
                    <v-row v-if="isErs">
                        <v-text-field
                            label="Cable type detail"
                            v-model="cableTypeDetailLocal"
                            placeholder="Example, 50m of 540"
                        ></v-text-field>
                    </v-row>

                    <v-row>
                        <v-select
                            label="Cause"
                            v-model="causeIdLocal"
                            :items="lookups.allCauses"
                            item-text="name"
                            item-value="id"
                            :rules="rules.required"
                            clearable
                        ></v-select>
                    </v-row>
                    
                    <v-row>
                        <v-select
                            label="Incident type"
                            v-model="typeIdLocal"
                            :items="lookups.allIncidentTypes"
                            item-text="name"
                            item-value="id"
                            :rules="rules.required"
                            clearable
                        ></v-select>
                    </v-row>
                    
                    <v-row>
                        <RichTextEditor
                            label="Incident description"
                            v-model="descriptionLocal"
                            :required="true"
                            :rules="rules.required"
                            hint="What was the damage?  What did you see? Who do you think caused the damage? Could the damage have been avoided? Was this part of a Diversion?"
                        />
                    </v-row>

                    <v-row>
                        <v-select
                            label="Damaged apparatus location"
                            v-model="subLocationIdLocal"
                            :items="lookups.allIncidentSubLocations"
                            item-text="name"
                            item-value="id"
                            :rules="rules.required"
                            clearable
                        ></v-select>
                    </v-row>
                    
                    <v-row>
                        <v-select
                            label="Does this relate to PIA work?"
                            v-model="relatesToPiaIdLocal"
                            :items="lookups.allPiaOptions"
                            item-text="name"
                            item-value="id"
                            :rules="rules.required"
                            v-on:change="refreshValidation()"
                            clearable
                        ></v-select>
                    </v-row>

                    <div v-if="isPia">

                        <v-row>
                            <v-text-field
                                label="Grid reference"
                                v-model="piaGridReferenceLocal"
                                :rules="rules.required"
                            ></v-text-field>
                        </v-row>
                        
                        <v-row>
                            <v-text-field
                                label="PIA whereabouts ID"
                                v-model="piaWhereaboutsIdLocal"
                                :rules="rules.required"
                            ></v-text-field>
                        </v-row>
                        
                        <v-row>
                            <v-text-field
                                label="PIA NOI Ref"
                                v-model="piaExchangeCodeLocal"
                                :rules="rules.required"
                            ></v-text-field>
                        </v-row>

                        <v-row>
                            <v-select
                                label="PIA - overhead or underground?"
                                v-model="piaOverheadUndergroundIdLocal"
                                :items="lookups.allOverheadOptions"
                                item-text="name"
                                item-value="id"
                                :rules="rules.required"
                                clearable
                            ></v-select>
                        </v-row>

                    </div>
                    
                </v-form>
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script>
import instructionsAgent from "../instructionsAgent.js";
import optionSetAgent from "../../Ods/optionSetAgent.js";
import RichTextEditor from "../../../components/RichTextEditor.vue";

export default {
    components: {
        RichTextEditor,
    },

    props: {
        id: String,
        typeSystemName: String,

        causeId: Number,
        typeId: Number,
        description: String,
        subLocationId: Number,
        relatesToPiaId: Number,
        piaGridReference: String,
        piaWhereaboutsId: String,
        piaExchangeCode: String,
        piaOverheadUndergroundId: Number,

        // ERS
        businessPremiseId: Number,
        dropCableId: Number,
        cableTypeId: Number,
        cableTypeDetail: String,
    },
    
    data: function () {
        return {
            causeIdLocal: this.causeId,
            typeIdLocal: this.typeId,
            descriptionLocal: this.description,
            subLocationIdLocal: this.subLocationId,
            relatesToPiaIdLocal: this.relatesToPiaId,
            piaGridReferenceLocal: this.piaGridReference,
            piaWhereaboutsIdLocal: this.piaWhereaboutsId,
            piaExchangeCodeLocal: this.piaExchangeCode,
            piaOverheadUndergroundIdLocal: this.piaOverheadUndergroundId,

            // ERS
            businessPremiseIdLocal: this.businessPremiseId,
            dropCableIdLocal: this.dropCableId,
            cableTypeIdLocal: this.cableTypeId,
            cableTypeDetailLocal: this.cableTypeDetail,
            
            lookups: {
                allCauses: [],
                allIncidentTypes: [],
                allIncidentSubLocations: [],
                allPiaOptions: [],
                allOverheadOptions: [],
                allBoolOptions: [],
                allCableTypes: [],
            },

            isValid: true,

            rules: {
                required: [v => !!v || "Required"],
            }
        };
    },
    
    computed: {
        isErs: function () {
            return (this.typeSystemName || "").endsWith("ers");
        },

        isPia: function () {
            return this.relatesToPiaIdLocal === 500000181;  // Yes
        },
    },

    watch: {
        causeIdLocal: function (val) {
            this.getIncidentTypesStriped();
        },
    },

    mounted: function () {
        var l = this.$coreUi.loading();

        Promise.all([
            optionSetAgent.getOptionSetValues("vm-incident-cause").then(data => {
                this.lookups.allCauses = data.optionSetValueProperties;

                if (this.lookups.allCauses.every(x => x.id !== this.causeIdLocal))
                    this.causeIdLocal = null;
            }).catch(console.error),

            this.getIncidentTypesStriped(),

            optionSetAgent.getOptionSetValues("vm-incident-sub-location").then(data => {
                this.lookups.allIncidentSubLocations = data.optionSetValueProperties;

                if (this.lookups.allIncidentSubLocations.every(x => x.id !== this.subLocationIdLocal))
                    this.subLocationIdLocal = null;
            }).catch(console.error),

            optionSetAgent.getOptionSetValues("vm-pia-option-set").then(data => {
                this.lookups.allPiaOptions = data.optionSetValueProperties;

                if (this.lookups.allPiaOptions.every(x => x.id !== this.relatesToPiaIdLocal))
                     this.relatesToPiaIdLocal = null;
            }).catch(console.error),

            optionSetAgent.getOptionSetValues("vm-network---overhead-or-underground").then(data => {
                this.lookups.allOverheadOptions = data.optionSetValueProperties;

                if (this.lookups.allOverheadOptions.every(x => x.id !== this.piaOverheadUndergroundIdLocal))
                     this.piaOverheadUndergroundIdLocal = null;
            }).catch(console.error),

            optionSetAgent.getOptionSetValues("vm-yes-no").then(data => {
                this.lookups.allBoolOptions = data.optionSetValueProperties;

                if (this.lookups.allBoolOptions.every(x => x.id !== this.businessPremiseIdLocal))
                     this.businessPremiseIdLocal = null;
                     
                if (this.lookups.allBoolOptions.every(x => x.id !== this.dropCableIdLocal))
                     this.dropCableIdLocal = null;
            }).catch(console.error),

            optionSetAgent.getOptionSetValues("vm-cable-type").then(data => {
                this.lookups.allCableTypes = data.optionSetValueProperties;

                if (this.lookups.allCableTypes.every(x => x.id !== this.cableTypeIdLocal))
                     this.cableTypeIdLocal = null;
            }).catch(console.error),

        ]).then(() => {
            l.dismiss();
            this.refreshValidation();
        });
    },

    methods: {
        refreshValidation () {
            this.$nextTick(() => {
                this.$refs.form.validate();
            });
        },

        getIncidentTypesStriped () {
            
            var stripingContext = {
                sharedoId: this.id,
                incidentDetailsCauseId: this.causeIdLocal,
                // I don't think they have  matching rules on any other fields
            };

            var addNode = function (node, allNodes, parent, leafOnly) {
                    if (parent)
                        node.name = `${parent.name} > ${node.name}`;

                    if (leafOnly !== true || node.children.length === 0)
                        allNodes.push(node);

                    node.children.forEach(c => {
                        addNode(c, allNodes, node, leafOnly);
                    });
            };

            return optionSetAgent.getOptionSetValues("vm-incident-type", stripingContext).then(data => {

                // Add nodes recursively
                data.optionSetValueProperties.forEach(v => {
                    addNode(v, this.lookups.allIncidentTypes, null, true);
                })

                if (this.lookups.allIncidentTypes.every(x => x.id !== this.typeIdLocal))
                    this.typeIdLocal = null;
            }).catch(console.error);
        },

        save () {
            var l = this.$coreUi.loading();
            
            // Load workItem
            instructionsAgent.getInstruction(this.id).then((result) => {

                // Set fields and save
                result.aspectData.incidentDetailsCause.incidentCauseId = this.causeIdLocal;
                result.aspectData.incidentDetailsType.incidentTypeId = this.typeIdLocal;
                result.aspectData.incidentDetailsDescription.incidentDescription = this.descriptionLocal || "";
                result.aspectData.incidentDetailsType.incidentDetailsLocation = this.subLocationIdLocal;

                result.aspectData.formBuilder.formData["vm-incident-details-extended-sub-loc-lov"] = this.subLocationIdLocal;
                result.aspectData.formBuilder.formData["does-this-relate-to-pia"] = this.relatesToPiaIdLocal;
                result.aspectData.formBuilder.formData["vm-pia-grid-reference"] = this.isPia ? this.piaGridReferenceLocal : null;
                result.aspectData.formBuilder.formData["vm-pia-whereabouts-id"] = this.isPia ? this.piaWhereaboutsIdLocal : null;
                result.aspectData.formBuilder.formData["vm-pia-exchange-code"] = this.isPia ? this.piaExchangeCodeLocal : null;
                result.aspectData.formBuilder.formData["vm-pia-overhead-underground"] = this.isPia ? this.piaOverheadUndergroundIdLocal : null;

                // ERS
                result.aspectData.formBuilder.formData["vm-instruction-dispute-claimant-pd-ers-details-business-premise-bool"] = this.isErs ? this.businessPremiseIdLocal : null;
                result.aspectData.formBuilder.formData["vm-instruction-dispute-claimant-pd-ers-details-drop-cable-rel-bool"] = this.isErs ? this.dropCableIdLocal : null;
                result.aspectData.formBuilder.formData["vm-instruction-dispute-claimant-pd-ers-details-cable-type-lov"] = this.isErs ? this.cableTypeIdLocal : null;
                result.aspectData.formBuilder.formData["vm-instruction-dispute-claimant-pd-ers-details-cable-type-detail-text"] = this.isErs ? this.cableTypeDetailLocal : null;

                // Don't invoke these aspect savers (they could fail)
                delete result.aspectData.odsEntityPicker;
                delete result.aspectData.instructionWorkTypeDetails;
                delete result.aspectData.instructionB2BDetails;
                delete result.aspectData.locationCoordinate;
                
                instructionsAgent.saveInstruction(this.id, result).then(() => {

                    l.dismiss();
                    this.close(true);

                }).catch(console.error);

            }).catch(console.error);
        },

        close (result) {
            this.$emit("close", result);
        }
    },
};
</script>

<style>
.incident-details-form.theme--light.v-sheet .tiptap-vuetify-editor > .v-card {
    margin: 0;
}

/* Avoid toolbar wrapping */
.incident-details-form.theme--light.v-sheet .tiptap-vuetify-editor__toolbar > .v-toolbar {
    padding: 5px 1px;
}
</style>